.todays_activity_container {
    
    /*margin-top: 12px;*/
}

@media (max-width: 1350px) {
    .todays_activity_container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-gap: 10px;
        font-size: var(--defaultTextSize);
        justify-content: center;
        overflow-wrap: normal;
    }
}


@media (max-width: 1020px) {
    .todays_activity_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: var(--defaultTextSize);
        justify-content: center;
        overflow-wrap:unset;
    }
}
