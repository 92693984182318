.callTile {
    padding: 10px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    font-size: var(--defaultTextSize);
    width: 100%;
}

.tileTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tileDetailsContainer {
    width: 100%;
    margin: 5px;
}

.durationField {
    text-align: -webkit-right;
}
