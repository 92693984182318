aside.left {
    float: left;
    margin-top: 50px;
    display: block;
    flex: 1;
    max-width: 150px;
}


.main_content {
    display: block;
    flex: 2;
}


main aside:first-child {
    grid-area: leftside;
    max-width: 200px;
}
main article {
    grid-area: maincontent;
}
main aside:last-child {
    grid-area: rightside;
}

main {
    display: flex;
    flex-direction: row;
}

@media (max-width: 600px) {
    main {
        flex-direction: column;
    }
}

main * {
    overflow-y: visible;
    overflow-x: visible;
}

/*
main {
      font-size: 28px; 
      padding: 0px 10px;
      margin-top: 30px
    }
*/



/*
aside.left {
  height: 100%;
  width: 140px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  padding-top: 20px;
}
*/