/*
.care_selected { height:25px }


*/

.aqtooltip {
/*    position: relative;*/
    display: inline-block;
    border-bottom: 1px dotted #4cc1bd; /* If you want dots under the hoverable text */

}

.aqtooltip .aqtooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #4cc1bd;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    border: 1px solid white;
    position: absolute;
    z-index: 1;
    top: 52px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.aqtooltip:hover .aqtooltiptext {
    visibility: visible;
}


.aqtooltip_comment {
    justify-content:center;
    height:100%;
}

.aqtooltip_comment .aqtooltiptext_comment {
    visibility: hidden;
    width: 120px;
    background-color: darkgray;
    color: #fff;
    text-align: center;
    padding: 1px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.aqtooltip_comment:hover .aqtooltiptext_comment {
    visibility: visible;
}
/*
.dashboard_careclick_comment_icon {
    background-color: lightgray;
    color: darkgray;
    font-size: 15px;
    font-weight: bold;
    height: 20px;
    width: 20px;
    border-radius:4px;
    margin:1px;
}
*/
.aqtooltiptext_comment_title{
    font-weight:bold;
    font-size: var(--defaultTextSize);
    color:white;
    text-align:left;
    padding-left: 10px;        
}

.aqtooltiptext_comment_text{
    background-color:white;
    color:darkgray;
    margin: 2px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding:2px;
    text-align:left;
    padding-left: 10px;
}