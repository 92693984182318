.move-floor-plan-container {

    width: 1000px;
    height: 500px;
  
    position: relative;
    display: flex;
    flex-direction: row;
  }

.move-floor-plan-container .details {

  display: flex;
  flex-direction: column;
  margin: 10px;
  margin-right: 30px;

}

  
  .move-floor-plan-svg {
    width: 800px;
    height: 100%;
    border: 10px black solid;
  }

