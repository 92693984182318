.aqForm_container {
    /* background-color: blue; */
    width: 500px;
}

.aq_description_label {
    margin-top: 50px;
}

.aq_description_text {
    font-size: 16px;
    /* font-weight: bold; */
    color: #333;
}