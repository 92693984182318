
.aq_form {
  font-size: 12.5px;
}

.aq_form .details {
    font-weight: bold;
}

.aq_form a {
  color: var(--primaryColour);
}


.aq_grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 80px;
    /*gap: 20px;*/ /* Adjust as needed */
  }
/*
  .aq_left-column {
    align-self: start;
  }

  .aq_right-column {
    align-self: end;
  }
*/
  .aq_grid-item {
    /*border: 1px solid #ccc;*/
    padding: 10px;
  }

  .aq_room_div {
    /*border: 1px solid #ccc;*/
    padding-left: 10px;
    padding-right: 10px;
  }