.dashboard_container_grid {
    display: grid;
    /*grid-template-columns: repeat(3, 1fr);*/
    /*grid-template-columns: repeat(3, 400px);*/
    grid-template-columns: repeat(3, 33%);
    grid-template-rows: repeat(3, minmax(250px, 330px));
    gap: 5px;
    padding: 10px;
    width: 100%;
}
/*
@media (max-width: 1400px) {
  .dashboard_container_grid {
    display: flex;
    flex-direction: column;
    
    grid-template-columns: repeat(2, 400px);
      grid-template-rows: repeat(2, minmax(250px, 330px));

    }
}
*/

@media (max-width: 900px) {
    .dashboard_container_grid {
        display: flex;
        flex-direction: column;
    }
  }
  
.dashboard_container_grid_item {
    /*background-color: #3498db;*/
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
}
  
.dashboard_container_grid_item1 {
    grid-column: span 1;
    grid-row: span 1;
}
  
  .dashboard_container_grid_item2 {
    grid-column: span 2;
    /*grid-row: span 2;*/
  }
  
  .dashboard_container_grid_item3 {
    grid-column: span 1;
    /*grid-row: span 2;*/
  }
  
  .dashboard_container_grid_item4 {
    grid-column: span 2;
    grid-row: span 1;
  }


  @media (max-width: 600px) {
    .grid-container {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, minmax(100px, 1fr)); /* Adjusting maximum height for mobile */
    }
  }

  /* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  .dashboard_container_grid {
    display: flex;
    flex-direction: column;
}}

/* Styles for iPads */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .dashboard_container_grid {
    display: flex;
    flex-direction: column;
  }
}
