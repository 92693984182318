



.callTileTop {
    padding: 10px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-right: 9px;
    margin-bottom: 10px;
}



.topRowDetails{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 5px;
    font-size: var(--defaultTextSize);
    margin-right: 10px;
    align-items: center;
}