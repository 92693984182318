.subscription_container{
    width: 100%;
    /*text-align: -webkit-center;*/
    padding-left: 20%;
    padding-right: 20%;
}

.subscription_container div {
    font-size: var(--defaultTextSize);
    color: gray;
}

