.usermanagement_user_roles_menu {
    display: flex;
    flex-direction: row;
}

.usermanagement_user_roles_menu_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.usermanagement_user_roles_menu div {
    margin-right: 50px;
    margin-bottom: 20px;
}

.usermanagement_list_container {
    /*border: 1px solid var(--textColourGrey);*/
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
}

.usermanagement_list_search_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%
}

.button_aqtable{
    border: solid var(--textColourSidebar) 1px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    /*color: black;*/
    border-radius: 6px;
    height:80%;
    align-self: center;
}

/*
.button_1 {
    border: solid var(--textColourSidebar) 1px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 6px;
}
*/

.button_1:disabled {
    background-color: #cccccc; /* Light gray */
    color: #666666; /* Dark gray */
    cursor: not-allowed;
}

.input_1 {
    border: solid var(--textColourSidebar) 1px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 6px;
}

.usermanagement_users_list {
    display: grid;
    grid-template-columns: 25% 25% 15% 15% 15% 5%;
    grid-gap: 10px;
    width: 100%;
    max-width: 1200px; /* Optional: Set a max-width for the grid container */
    margin: 0 auto;    /* Optional: Center the grid container on the page */
}

.usermanagement_users_list * {
    text-align: left;
    padding: 0px;
    border-radius: 8px;
}

.form_error {
    max-width: 300px;
    color: red;
    margin: 20px;
}

.button_2 {
    border: 1px solid black;
    border-radius: 6px;
    background-color: var(--secondaryColour);
    padding: 5px;
    margin-bottom:10px;
}