.notifications_container{
    width:100%;
    align-items:center
}
.notification_text{
    font-size: var(--defaultTextSize);
    /* overflow: -moz-hidden-unscrollable; */
    color: white;
    display: inline-block;
    /* text-overflow: ellipsis;  */
    white-space: nowrap;
}
.notification_nodata{
    font-size: var(--defaultTextSize);
    /* overflow: -moz-hidden-unscrollable; */
    
    
    color: black;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.caughtUP{
    font-size:17px ;
    font-weight: bold;
    color: rgb(64, 60, 60);
}
.nonotif{
    
    color: rgb(64, 60, 60);
}
.notifications_row{
    align-items: stretch;
    text-align: center;
    border-radius: 6px;
    color: white;
    width: 100%;
    height: 70px;
    padding: 0px;
}
.notifications_row:hover{
    cursor: pointer;
}
.notifications_Battery{
    background-color: #5DCC8E;
}


.notifications_InactiveUnit{
    /* background-color: #4cc1bd !important; */
    background-color: rgb((227, 228, 229, 1.0))!important;
}

.notification_charging {
    background-color: #FFC300 !important;
    /*color: grey !important;*/
    
}

.notifications_low_battery {
    /* background-color: #ee621c !important; */
    /* background-color: #ec8d5e !important; */
    background-color: #fba004 !important;
    
}
