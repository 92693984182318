@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--defaultTextSize) !important;
}

code {
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;*/
}


.reports_careclicks {
  width: 30px;
}

/*hacks*/

.bg-blue-500 {
  background-color: #4cc1bd !important;
}
.bg-blue-100 {
  background-color: #dcf5f3 !important;
}
/*
path[name="Atten  dance"] {
  fill:#dcf5f3; 
  stroke: #4cc1bd;
}
*/

button.basic {
  padding: 5px;
  border: 1px solid #4cc1bd;
  border-radius: 6px;
}

button.basic:hover {
  background-color: #dcf5f3;
  color:#4cc1bd
}

.tooltipChart {
  pointer-events: none; 
  position: absolute; 
  top: 100px; 
  left: 100px; 
  /*outline: none; */
  border: lightgray solid 1px;
  transform: translate(298.45px, 0px);
  z-index: 10;
  background-color: white;
  border-radius: 6px;
 
}

/* Width and Color of the scrollbar track */
::-webkit-scrollbar {
  width: 12px;
}

/* Color of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #4cc1bd;
  border-radius: 6px; /* Round the scrollbar thumb */
}

/* Color of the scrollbar thumb when hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #4cc1bd;
}

/* Color of the scrollbar track when hovered */
::-webkit-scrollbar-track:hover {
  background-color: #f1f1f1;
}

/* Width and Color of the scrollbar track when pressed */
::-webkit-scrollbar-thumb:active {
  background-color: #4cc1bd;
}

/* Width and Color of the scrollbar track when pressed */
::-webkit-scrollbar-track:active {
  background-color: #ccc;
}
/*
table.calls {

  margin-left: auto;
  margin-right: auto;
  width: auto;
  overflow: auto;
  overflow-y: scroll;
  border-collapse: separate;
  text-indent: 0;
  border-spacing: 2px;
  font-size: var(--defaultTextSize);

}

table.calls thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}


table.calls tr:nth-child(odd) {
    background: #dcf5f3;
}

table.calls th {
  padding: 2px;
  font-size: .9em;
  color: #fff;
  background-color: #4cc1bd;
  border: none;
  height: 35px;
  text-align: center;
  align-items: end;
  vertical-align: center;
}

table.calls tbody {
  display: block;
  overflow: auto;
  display: table-header-group;
}

table.calls tr:nth-child(odd) {
  background: #dcf5f3;
}

table.calls td {
  padding: 10px;
  text-align: center;
  font-size: .9em;
  font-weight: 400;
  border: 1px solid #4cc1bd;
  color: #4cc1bd;
  padding: 5px;
}
*/
table.calls {

  margin-left: auto;
  margin-right: auto;
  width: auto;
  overflow: auto;
  overflow-y: scroll;
  border-collapse: separate;
  text-indent: 0;
  border-spacing: 2px;
  font-size: 12.5px;
  

}

.resize_test{
  resize: both;
  overflow: auto;
}


table.calls thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}




table.calls th {
  padding: 2px;
  font-size: .9em;
  color: #fff;
  background-color: #4cc1bd;
  border: none;
  height: 35px;
  text-align: center;
  align-items: end;
  vertical-align: center;
}

table.calls tbody {
  display: block;
  overflow: auto;
  display: table-header-group;
}



table.calls td {
  padding: 10px;
  text-align: center;
  font-size: .9em;
  font-weight: 400;
  border: 1px solid #4cc1bd;
  color: #4cc1bd;
  padding: 5px;
}

.table_pagination {
  font-size: 1.7vh;
  color: gray;
}

.bar_connect {
  background-color: #dcf5f3;
  color: grey;
  /*margin: 1px;*/
}

.tooltip-container {
  position: relative;
}

.tooltip-div {
  height: 30px;
  color: gray;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  border-radius: 5px;
}

.tooltip {
  z-index: 10;
  font-size: var(--defaultTextSize);
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4cc1bd;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
}
.tooltip-container:hover .tooltip {
  display: block;
}

.sidebar_icons {
  width:26px;
  height:26px;
  color: var(--textColourSidebar);
}

.dashboard_section_title { 
  font-size: 1.8vh; 
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.modal {
  background: #fff;
  padding: 20px 0px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-height: 94%;
  /* overflow-y: scroll; */
}

.modal p {
  margin: 0;
}
/*
.modal button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
*/

h1, h2 {
  color: var(--textColourSidebar) !important;
}

td {
  overflow:visible !important;
}

.tooltipStyle { font-size: 12px;}

.formFieldError {color: red;}

button {
  font-size: var(--defaultTextSize) !important;
}

#notistack-snackbar{
  font-size: var(--defaultTextSize);
}

.ant-picker-range-separator{
  overflow: hidden !important;
}