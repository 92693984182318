.dashboard_layout {
    display: flex;
    flex-direction: column;
    background-color: white;
    position: relative;  /* Make sure the layout has a position context */
    overflow: visible; 
}

.two_column_split {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.cards_list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: visible;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;    
}

@media (max-width: 1029px) {
    .cards_list {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;    
    }

    .two_column_split {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    
}

.padding-10 { padding: 10px } 

.card_width_small {
    width:300px;
}

.card_template {
    padding:10px;
    margin:10px;
}

.card_template_no_padding {
    padding:0px !important;
    margin:10px;
}


.card_width_medium {
    width:500px;
}

.card_width_half { 
    max-width: 50%;
    width:50%;
}

.card_width_large {
    width:800px;
}

.card_width_full {
    width:100%;
}



.card_layout {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
}

.insight_barChart{
    cursor: pointer;
    max-height: 500px;
}

.popup-container {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9999;  /* Ensure popup appears above other elements */
    background-color: white;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: auto;
}

.apply-button {
    z-index: 10000;
    padding: 5px 10px;
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.apply-button:hover {
    background-color: #40a9ff;
}