.notifications_container{
    color: var(--componentTextColour);
    font-size: var(--componentTextSize);
}

.notifications_row{
    display: grid;
    grid-template-columns: 50px 35% 45%;
    grid-gap: 5px;  
    align-items: center;
    padding: 5px;
    border-radius: 6px;
    background-color: gray;
    color: white;
    
}

.notifications_Battery{
    background-color: #5DCC8E;
}

