
.cal_twt_container {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
    border-radius: 6px;
    background-color:white;
    padding-right: 7px;
    padding-left: 7px;    
    min-height: 38px;
}

.cal_twt_selected {
    background-color: #4cc1bd;
    color: white;
    padding: 0.1em;
    border-radius: 4px;
    text-align: center;
    align-self: center;
    width: 50px;
    border-radius: 4px;
    
}

.cal_twt_option {
    background-color: white;
    color:#4cc1bd;
    padding: 0.1em;
    border-radius: 6px;
    text-align: center;
    align-self: center;
    border-radius: 4px;
    width: 50px;
    
}
