.formcontrol_formtextinput_container {

    display: flex;
    flex-direction: column;
    /*
    margin-top: 15px;
    margin-bottom: 15px;
    */
    font-size: var(--defaultTextSize);

}

.formcontrol_formtextinput_container .error {
    color: red;
    font-weight: 500;
}