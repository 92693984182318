.teardrop {
  max-height: 25px;
  max-width: 25px;
  position: absolute;
  top: 100;
  left: 100;
}

.xaxis { 
  font: 0.5em sans-serif; 
  fill: #868686;
  stroke-width:0px;
}
.axis {
  fill: none;

  
}
/*
svg g.drop path{
  stroke: blue;
  stroke-width: 3px;
  fill: green;
  fill-opacity: 0.3;
}
*/

text {
  opacity: 1;
  font-size: 1em
}

.kpiText {
  font-size: 0.6em
}

.targetLine {
  shape-rendering: crispEdges;
  stroke: #4CBCB9;
  stroke-width: 1;
}
/*
path, line {
  shape-rendering: crispEdges;
  stroke: #868686;
  stroke-width: 1;
}*/

.gBarChart {
/*
  width: 800px;
  height: 400px;

*/

  width: 100%; 
  height: auto;
  background-color: #dcf5f3;
  
}

.bar {
  display: inline-block;
  width: 100px;
  height: 120px;
  background-color:grey;
}

/*svg {
  font-family: "Helvetica Neue", Helvetica;
}*/
/*
line {
  shape-rendering: crispEdges;
  
  stroke: #000;
  stroke-width: 5px;
}
*/
.active-d3-item {
  cursor: pointer;
  
  stroke: magenta;
}


.axis path, .axis line {
  fill: none;
  stroke: black;
  shape-rendering: crispEdges;
}
.axis text {
  font-family: sans-serif;
  font-size: 11px;
}
.tooltip {
  position: absolute;
  text-align: center;
  width: 40px;
  height: auto;
  padding: 10px;
  background-color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  pointer-events: none;
}
#tooltip.hidden {
  display: none;
}
#tooltip p {
  margin: 0;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.x-axis {
  background-color:green;
}

.callReportContainer {
    padding-top: 30px;
    /*borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    color: "white",
    border: "1px",
    borderStyle:"solid",
    backgroundColor: props.backgroundColor ? props.backgroundColor : "white",
    borderColor: props.color ? props.color : colours[props.callType],
    */
    padding-left: 10px;
    padding-right: 10px;
    z-index: 0;
  }
