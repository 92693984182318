.barlist_container {
    display: flex;
    flex-direction: row;
    /*justify-content: space-between;*/
    width: 500px;
}
.barlist_container:hover {
    border: 1px solid green;
    border-radius: 6px;
}

.barlist_container .title {
    width: 150px;
}

.barlist_container * {
    margin: 5px;
}

.barlist_container .recs {
    background-color: lightblue;
    border-radius: 6px;
    color: white;
}

.margin_default {
    margin: 5px;
}


.callmonitor_call_container {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* Creates 7 columns with equal width */
    gap: 10px; 
}

.bold {
    font-weight: bold;
}