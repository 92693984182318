.card_click_listener {
    /*border: 1px solid var(--textColourSidebar);*/
    border: 1px solid lightgray;
    border-radius: 6px;
    width: 23%;
    color: gray;
    cursor: pointer;
    min-width: 180px;
    max-height: 200px;
}

.dashboard_card_title {
    text-align: left;
    font-size: 1.7vh;
}

.dashboard_card_metric {
    width: 100%;
}

.dashboard_card_metric{
    font-weight: bold;
    font-size: 4vh;
    text-align-last: center;
}

.dashboard_card_high_low {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dashboard_card_layout{

}