.grid-split-2-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* This creates two columns with a 50/50 split */
    gap: 20px; /* You can adjust the gap between columns as needed */
}

.grid-split-2-item {
    /* Add styles for your grid items */
    border: 1px solid #333;
    padding: 20px;
    text-align: center;
}

.grid-split-3-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* This creates two columns with a 50/50 split */
    gap: 20px; /* You can adjust the gap between columns as needed */
}

.grid-split-3-item {
    /* Add styles for your grid items */
    border: 1px solid #333;
    padding: 20px;
    text-align: center;
}



.grid-30-70-container {
    display: grid;
    grid-template-columns: 20% 1fr; /* This creates two columns with a 50/50 split */
    gap: 20px; /* You can adjust the gap between columns as needed */
}