.sense_live_container {
}


.sense_div{
    padding: 5px;
    border-radius: 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: center;
    min-height: 70px;
}
.sense_text{
    
    font-size: var(--defaultTextSize);
    overflow: -moz-hidden-unscrollable;
    text-wrap: wrap;
    color: white;
    text-overflow: ellipsis;
 }
