@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.date-range-picker-popup-container {
  position: relative;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  color: #000
}

.date-input {
  width: 300px;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
}

.date-input span {
  font-size: 14px;
  color: #bfbfbf;
}

.date-input .calendar-icon {
  font-size: 18px;
  color: #e0e0e0;
}

.popup-container {
  position: absolute;
  top: 100%; 
  left: 0;
  background: white;
  border: 1px solid #d9d9d9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 9999;
  padding: 20px;
  width: auto;
}

.popup-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}



.presets {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 150px;
}


.preset {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  background: #f0f0f0;
  border-radius: 4px;
  font-size: 14px;
}

.preset:hover {
  background: #e6f7ff;
}

.unified-calendar {
  display: flex;
  justify-content: column;
}


.calendar-container {
  margin-right: 20px;
}

.datepicker-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f0f0f0;
  font-size: 14px;
}

.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 40px;
}

.day, .day-name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
}

.day {
  cursor: pointer;
}

.day:hover {
  background-color: #e6f7ff;
}

.selected {
  background-color: #4CC1BD;
  color: white;
}

.in-range {
  background-color: #bae7ff;
}

.empty {
  visibility: hidden;
}

.time-pickers {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.time-picker {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.apply-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
}

.apply-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  width: 100%; /* Make sure it stretches the full width */
}

.apply-button {
  padding: 10px 20px;
  background-color: #4CC1BD;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10000;
}

.apply-button:hover {
  background-color: #40a9ff;
}

.time-select {
  display: flex;
  align-items: center;
  gap: 5px;
}

.time-select select {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.disabled {
  color: gray;
  pointer-events: none;
}

/* Highlight the start date in red */
.start-date {
  background-color: #4CC1BD;
  color: white;
}

/* Highlight the end date in blue */
.end-date {
  background-color: #4CC1BD;
  color: white;
}
.date-input {
  font-size: 16px;
  font-weight: 500;
}

.calendar-container .day,
.calendar-container .day-name {
  font-size: 14px;
}

.apply-button {
  font-size: 14px;
  font-weight: 700;
}