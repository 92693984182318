.action_button{
  width:70px;
}
.add_button{
  background-color:#E6E6E6;
  font-size: var(--defaultTextSize);
  border: solid var(--textColourSidebar) 1px;
  padding: 5px 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: black;
  border-radius: 6px;
  cursor: pointer;
  
    background-color:#D0F5F6;
    border: solid 1px #4CC1BD;
}

select{
  width: 150px;
}
.config_input{
  width: 150px;
}
.readonly_input{
   background-color: rgb(233, 230, 230);
}
.red_star{
  color:rgb(236, 90, 90);
}
.upload_area{
  width: 200px;
  border: solid 1px #7f8484;
  height: 180px;
  background-color: white;
  /* background-color: #cfd9d9; */
  padding: 10PX;
  border-radius: 10px;
  border-style: dotted;
}
.upload_area_on{
  width: 200px;
  border: solid 1px #7f8484;
  height: 180px;
  background-color: #cfd9d9;
  padding: 10PX;
  border-radius: 10px;
  border-style: dotted;
}
.upload_icon{
  margin-left: 40px;
}
.file_btn{
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
  border : solid 1px rgb(175, 173, 173);
  width: 175px;
}
.file_btn::file-selector-button{
  background-color: #D0F5F6;
    border: solid 1px #4CC1BD;
  border-radius: 5px;
  padding: 0px 2px;
  color: black;
  cursor: pointer;
}
.unit_edit_field{
  width: 120px;
  height: 30px;
   margin: 0px 3px;
   padding: 0px 5px;
   border-radius: 5px;
   border: solid 1px rgb(134, 131, 131);
}

.unit_header_field{
  width: 120px;
   margin: 0px 3px;
   text-align: center;
   font-weight: bold;
}
.bottom_field{
  position: absolute;
  bottom: 0;
  right:0;
  /* margin-bottom: 10px; */
  margin-bottom: 0px;
}
.btn_size{
  width:60px;
  text-align: center;
}

.div_default{
background-color:white;
width: 100%;
border-radius: 5px;
padding: 5px;
margin-bottom: 3px;
}
.div_selected{
background-color:white;
}

.div_error{
  background-color: #f3bfb5;
/* background-color:white; */
/* border:solid 1px #d10404; */
}
.div_notSelected{
  background-color: #bae7e5;
}
.checkBox{
  width: 30px;
}
.delete_button{
  height: 30px;
  margin-left: 3px;
  /* width: 30px; */
  /* margin:0px ; */
  padding: 5px;
  background-color: #F8A392;
    border: solid var(--textColourSidebar) 1px;
    /* padding: 5px 15px; */
    /* margin-right: 5px; */
    /* margin-bottom: 10px; */
    color: black;
    border-radius: 6px;
     /* width: max-content; */
    /* display: inline-flex; */
}