.disabled_save{
  border: solid var(--textColourSidebar) 1px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    color: black;
    background-color:#D0F5F6;
    border: solid 1px #4CC1BD;
    border-radius: 6px;
    width: 60px;
}
.disabled_save:disabled, .disabled_save[disabled]{
  background-color: #E6E6E6;
  border:solid 1px #797979;
  color:#797979 ;
}
