.form_container {
    
}

.form_container_header {
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.form_container_header :first-child {
    margin-right: 20px;
}

.input_search{
    width: 500px;
    font-size: var(--defaultTextSize) !important;
}