.email_readonly{
    background-color: rgb(233, 230, 230);
}
.tag-item{
    background-color: #D0F5F6;
    color: black;
    display: inline-block;
    padding: .5em .75em;
    font-size: var(--defaultTextSize);
    margin-right: 10px;
    margin-bottom: 5px;
    border: solid 1px #76b5b7;
    border-radius: 10px;
}
.tag-item .close{
    height: 20px;
    width: 20px;
    color: black;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 15px;
    cursor: pointer;
    font-weight:10;
}
.clear_button{
    background-color: #F8A392;
    border: solid var(--textColourSidebar) 1px;
    padding: 5px 15px;
    margin-right: 5px;
    margin-bottom: 10px;
    color: black;
    border-radius: 6px;
    width: max-content;
    display: inline-flex;
}
.clear_button span{
    height: 20px;
    width: 20px;
    color: black;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 15px;
    cursor: pointer;
    font-weight: bold;
}
.clear_button img{
    height: 16px;
    margin-left: 5px;
}
.tag_input{
    border-radius: 10px;
    border: solid 1px grey;
    padding: 5px;
}